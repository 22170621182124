
import { defineComponent, computed, watch } from 'vue';
import { useRoute } from 'vue-router';
import SectionHeader from '@/components/global/SectionHeader.vue';
import { routeParamsToVerse } from '@/router/route_to_verse';
import { isLoaded } from '@/interfaces/RemoteData';
import { getManuscriptVerseresults, manuscriptVerseResults } from '@/api/manuscripts';
import { webWatcher, manuscriptWeb } from '@/api/web';
import VerseBar from '@/components/verse_navigation/VerseBackAndForth.vue';
import ImageHolder from '@/components/global/ImageHolder.vue';
import { ManuscriptPageResult } from '@/interfaces/ManuscriptPageResult';
import { useMeta } from 'vue-meta';
import { useI18n } from 'vue-i18n';
import ManuscriptSelect from '@/components/manuscripts/ManuscriptSelect.vue';

export default defineComponent({
  components: {
    SectionHeader,
    VerseBar,
    ImageHolder,
    ManuscriptSelect,
  },
  setup() {
    const { t } = useI18n({
      useScope: 'global',
      inheritLocale: true,
    });
    const route = useRoute();

    const title = computed(
      () => `${t('navigation.manuscripts')} ${t('global.sura')} ${route.params.sura} ${t(
        'global.verse',
      )} ${route.params.verse}`,
    );
    const description = computed(
      () => `${t('navigation.manuscripts')} ${t('global.sura')} ${route.params.sura} ${t(
        'global.verse',
      )} ${route.params.verse}`,
    );
    const canonicalurl = computed(
      () => `https://corpuscoranicum.de/${route.params.lang}/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/manuscripts`,
    );
    const alternate_de = computed(
      () => `https://corpuscoranicum.de/de/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/manuscripts`,
    );
    const alternate_en = computed(
      () => `https://corpuscoranicum.de/en/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/manuscripts`,
    );
    const alternate_fr = computed(
      () => `https://corpuscoranicum.de/fr/verse-navigator/sura/${route.params.sura}/verse/${route.params.verse}/manuscripts`,
    );
    const meta = computed(() => ({
      title: title.value,
      description: description.value,
      link: [
        {
          rel: 'canonical',
          href: canonicalurl.value,
        },
        {
          rel: 'alternate',
          hreflang: 'de',
          href: alternate_de.value,
        },
        {
          rel: 'alternate',
          hreflang: 'en',
          href: alternate_en.value,
        },
        {
          rel: 'alternate',
          hreflang: 'fr',
          href: alternate_fr.value,
        },
        {
          rel: 'alternate',
          hreflang: 'x-default',
          href: alternate_de.value,
        },
      ],
      htmlAttrs: { lang: route.params.lang },
    }));
    useMeta(meta);
    webWatcher(route, 'manuscripts', manuscriptWeb);
    getManuscriptVerseresults(routeParamsToVerse(route.params));

    watch([() => route.params], () => {
      if (route.name === 'VerseManuscriptResults') {
        getManuscriptVerseresults(routeParamsToVerse(route.params));
      }
    });

    function listPages(pages: ManuscriptPageResult[]) {
      return `${t('manuscripts.pages')}: ${pages.map((p) => p.folio + p.side).join(', ')}.`;
    }

    return {
      manuscriptVerseResults,
      manuscriptWeb,
      isLoaded,
      listPages,
    };
  },
});
